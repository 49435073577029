<template>
  <div class="admin">
    <div class="sub-wrap">
      <div class="container">
        <header class="admin-header">
          <h1>Benutzer</h1>
        </header>
        <admin-nav></admin-nav>
        <div class="admin-body">
          <div class="form-wrap" style="margin-bottom: 20px">
            <input
              v-model="search_phrase"
              v-on:input="search_handler"
              class="form-input"
              type="text"
              name="search_phrase"
              id="search_phrase"
              placeholder="Suchbegriff eingeben..."
            />
          </div>

          <div v-if="loading">
            <div class="loading-wrap">
              <div class="loading-spinner"></div>
            </div>
          </div>
          <div v-else>
            <table class="table">
              <thead>
                <tr>
                  <th></th>
                  <th>E-Mail</th>
                  <th>{{ $t("global.name") }}</th>
                  <th>{{ $t("global.balance") }}</th>
                  <th>Datum</th>
                  <th style="text-align: center; white-space: nowrap">Geworben?</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="user in users" v-bind:key="user.uuid">
                  <td width="10%">
                    <img :src="user.avatar" style="height: 30px; width: 30px" />
                  </td>
                  <td width="30%">{{ user.email }}</td>
                  <td width="20%">{{ user.first_name }} {{ user.last_name }}</td>
                  <td width="15%">{{ user.balance_formatted }}</td>
                  <td width="15%">{{ user.created_at_formatted }}</td>
                  <td width="5%" style="text-align: center">
                    <span v-if="user.referred_by" class="material-icons">check_box</span>
                  </td>
                  <td width="10%" style="text-align: right">
                    <router-link :to="'/admin/users/' + user.uuid" class="campaign-wrap">
                      <span class="material-icons" style="color: #444">create</span>
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>

<script>
import axios from "axios";
import AdminNav from "@/components/admin_nav";

export default {
  name: "dashboard",
  components: {
    AdminNav,
  },
  data() {
    return {
      search_phrase: "",
      users: [],
      loading: true,
    };
  },
  methods: {
    get_data() {
      axios
        .get(process.env.VUE_APP_BASE_API + "/admin/users", {
          headers: { Authorization: this.$store.getters.getToken },
        })
        .then((response) => {
          this.users = response.data.users;
          this.loading = false;
        });
    },
    search_handler() {
      axios
        .get(process.env.VUE_APP_BASE_API + "/admin/users?search_phrase=" + this.search_phrase, { headers: { Authorization: this.$store.getters.getToken } })
        .then((response) => {
          this.users = response.data.users;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.get_data();
  },
};
</script>
